<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('common.orders_list')"
      :sub-title="$t('dashboard.subtitle')"
      btn
      variant="main"
      :btn-title="$t('orders_page.add_new_order')"
      btn-prepend-icon="add-circle-icon"
      classes="text-bold-15 text-white  w-cus-sm-100"
      @btnAction="orderAdd"
    />

    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllOrders"
              :per-page="filterData.per_page"
              :items="getAllOrders"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'industry_preference' },
                { name: 'action' },
              ]"
            >
              <template slot="industry_preference" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                    data.item.industry_preference.name
                  }}</span>
                </div>
              </template>
              <!-- <template
                slot="amount"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span class="text-font-main mx-3">-----</span>
                </div>
              </template> -->
              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="orderDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status === 3"
                    link-class="py-0min-height: 52px;"
                    @click="orderRefusal(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <refusal-icon class="mx-2" />
                      {{ $t('common.refusal') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status === 3"
                    link-class="py-0min-height: 52px;"
                    @click="orderSharing(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <sharing-icon class="mx-2" />
                      {{ $t('common.sharing') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllOrders && getAllOrders.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
          <warning-modal
            variant="main"
            variant2="success"
            icon-name="confirmation-icon"
            :visible="showSharingOrder"
            :title="$t('common.sharing_project_title')"
            :sub-title="$t('common.sharing_project_subtitle')"
            :btn-title="$t('common.sharing')"
            @close="showSharingOrder = false"
            @submitAction="sharingOrder()"
          />
          <validation-observer
            ref="form"
            #default="{ invalid }"
            tag="form"
            style="overflow: visible"
          >
            <b-modal
              id="wameed-modal"
              v-model="showRejectedOrder"
              header-class="py-5 px-5"
              body-class="py-5 px-5"
              title=""
              no-fade
              scrollable
              hide-header
            >
              <div class="align-items-center w-100">
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-between
                    align-items-center
                    w-75
                    mx-auto
                    mt-5
                  "
                >
                  <b-avatar
                    :variant="'light-danger'"
                    rounded
                    size="76"
                    class="mb-5"
                  >
                    <rejected-icon />
                  </b-avatar>

                  <h4 class="text-med-20 py-0 text-dark">
                    {{ $t('common.rejected_project_title') }}
                  </h4>
                  <h4 class="text-reg-16 py-0 text-center text-font-secondary">
                    {{ $t('common.rejected_project_subtitle') }}
                  </h4>
                </div>
              </div>
              <wameed-form refs="addOfferForm">
                <template slot="form-content" slot-scope="{ invalid }">
                  <div class="py-1 px-1">
                    <text-area
                      id="new-password"
                      v-model="rejectedDescription"
                      :label="$t('common.reason_refuse')"
                      field-classes="mb-0"
                      input-classes="text-med-14 text-font-main"
                      name="rejectedDescription"
                      rules="required"
                      :placeholder="$t('common.reason_refuse_placeholder')"
                      rows="5"
                    />
                  </div>
                </template>
              </wameed-form>
              <template slot="modal-footer">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    w-100
                  "
                >
                  <wameed-btn
                    classes="  text-med-14 text-white rounded-10"
                    :title="$t('common.refusal')"
                    :disabled="invalid"
                    type="submit"
                    variant="danger"
                    @submitAction="rejectedOrder"
                  />
                  <wameed-btn
                    classes="  text-med-14 text-font-secondary rounded-10 "
                    :title="$t('common.cancel')"
                    type="button"
                    variant="gray"
                    @submitAction="showRejectedOrder = false"
                  />
                </div>
              </template>
            </b-modal>
          </validation-observer>
        </b-col>

        <b-col
          v-if="getAllOrders && getAllOrders.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalOrders.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  data() {
    return {
      showSharingOrder: false,
      showRejectedOrder: false,
      rejectedDescription: '',
      orderId: null,
      filterData: {
        status: '0,1,2,3,4',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: this.$i18n.t('common.project_rejected'), id: 0 },
            { title: this.$i18n.t('common.project_active'), id: 1 },
            { title: this.$i18n.t('common.project_before_paying'), id: 2 },
            { title: this.$i18n.t('common.project_under_review'), id: 3 },
            { title: this.$i18n.t('common.project_offer_selection'), id: 4 },
          ],
        },
        {
          title: 'industry_preference',
          id: 'id',
          name: 'name',
          key: 'industry_preference',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'hr_job_title',
          label: this.$i18n.t('form_wizard_order.management_title_title'),
          sortable: true,
        },
        {
          key: 'employee_job_title',
          label: this.$i18n.t('form_wizard_order.job_title_required'),
          sortable: true,
        },
        {
          key: 'required_jobs_no',
          label: this.$i18n.t('orders_page.job_no'),
          sortable: true,
        },
        {
          key: 'industry_preference',
          label: this.$i18n.t('orders_page.sector'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('offers_page.status'),
          sortable: true,
        },
        { key: 'action', label: '', sortable: false },
      ],
      status: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllOrders: 'getAllOrders',
      getTotalOrders: 'getTotalOrders',
      getIndustryPreference: 'getIndustryPreference',
    }),
  },
  watch: {
    getIndustryPreference(data) {
      this.content[1].data = data.content;
    },
  },
  created() {
    this.loadData();
    this.loadIndustryPreference();
  },
  methods: {
    ...mapActions({
      loadOrders: 'loadOrders',
      loadIndustryPreference: 'loadIndustryPreference',
      changeOrdersStatus: 'changeOrdersStatus',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadOrders(this.filterData);
    },
    packageDetails(id) {
      this.$router.push({
        name: 'orders-list-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        if (this.filterData.status === '') {
          this.filterData.status = '0,1,2,3,4';
        }
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        // this.filterData[item.key] = item.selectedData.toString();
        this.filterData = {
          status: '0,1,2,3,4',
          industry_preference: '',
          page: 1,
          per_page: 10,
          order_by: 'desc',
          search: '',
        };
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    orderDetails(id) {
      this.$router.push({
        name: 'orders-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    orderRefusal(id) {
      this.showRejectedOrder = true;
      this.orderId = id;
    },
    orderSharing(id) {
      this.showSharingOrder = true;
      this.orderId = id;
    },
    sharingOrder() {
      this.changeOrdersStatus({
        id: this.orderId,
        status: 1,
      }).then(() => {
        this.showSharingOrder = false;
      });
    },
    rejectedOrder() {
      this.changeOrdersStatus({
        id: this.orderId,
        status: 0,
        description: this.rejectedDescription,
      }).then(() => {
        this.showRejectedOrder = false;
      });
    },
    orderAdd() {
      this.$router.push({
        name: 'orders-order-add',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
